/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import About from 'routes/about/index'

// Meta Component
import Meta from 'components/Meta/Meta'

// Story Route

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.about.seoMetaTags.tags} />
}

export default About

export const query = graphql`
  query AboutQuery {
    about: datoCmsPage(slug: { eq: "about" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
